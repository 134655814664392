
import React from "react";
import { Controller } from "react-hook-form";
import CustomSelect from "./CustomSelect";

const CustomSelectController = ({
  label,
  required,
  onInputChange,
  formatCreateLabel,
  name,
  control,
  helpTxt,
  validation,
  error,
  closeMenuOnSelect,
  inputClass,
  options,
  isLoading,
  getOptionLabel,
  getOptionValue,
  handleChangeFunc,
  onCreateOption,
  placeholder,
  maxSelect,
  isMulti,
  isCreatable,
  isCenter,
  isLazy,
  hideArrow,
  isPlaceholderLight,
  baseClass
}) => {
  return (
    <div className={`flex w-full flex-col items-start gap-2 ${baseClass}`}>
      {label && (
        <label className="text-sm sm:text-base font-bold">
          {label} 
        </label>
      )}
      <Controller
        name={name}
        control={control}
        rules={validation}
        render={({ field: { onChange, value = [], ref } }) => (
          <CustomSelect
            ref={ref}
            options={options}
            placeholder={placeholder}
            onChange={(e) => {
              onChange(e);
              handleChangeFunc && handleChangeFunc(e);
            }}
            isCenter={isCenter}
            onInputChange={onInputChange}
            isLoading={isLoading}
            isLazy={isLazy}
            closeMenuOnSelect={closeMenuOnSelect}
            value={value}
            inputClass={inputClass}
            error={error}
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
            maxSelect={maxSelect}
            isMulti={isMulti}
            isCreatable={isCreatable}
            onCreateOption={onCreateOption}
            formatCreateLabel={formatCreateLabel}
            hideArrow={hideArrow}
            isPlaceholderLight={isPlaceholderLight}
          />
        )}
      />
      {helpTxt && (
        <span className="text-theme-secondary text-xs md:text-xs">
          {helpTxt}
        </span>
      )}
      {error && error?.message && (
        <span className="text-red-500 text-xs md:text-xs">
          {error?.message}
        </span>
      )}
    </div>
  );
};

export default CustomSelectController;
