import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Toaster } from 'react-hot-toast';
import { ScheduleProvider } from './provider/ScheduleContext';
import { NextUIProvider } from "@nextui-org/react";
import { ProfileProvider } from './provider/ProfileContext';
import { BrowserRouter } from "react-router-dom";
import Layout from './Layout';
import { ErrorPageProvider } from './provider/ErrorPageContexr';
import { GOOGLE_CLOUD_CLIENT_ID } from './constants';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={GOOGLE_CLOUD_CLIENT_ID}>
      <NextUIProvider className='w-full'>
        <BrowserRouter>
          <ErrorPageProvider>
            <ProfileProvider>
              <ScheduleProvider>
                <Layout>
                  <App />
                  <Toaster/>
                </Layout>
              </ScheduleProvider>
            </ProfileProvider>
          </ErrorPageProvider>
        </BrowserRouter>
      </NextUIProvider>
    </GoogleOAuthProvider>
  </React.StrictMode>
);