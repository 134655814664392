export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
export const GOOGLE_CLOUD_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
//export const API_BASE_URL =
  //"https://ec2-3-139-27-159.us-east-2.compute.amazonaws.com/api";
export const API_BASE_URL = "https://www.plannedforyou.com/api";
export const BASE_URL = "https://backend-ydi5.onrender.com";
export const DESTIATIONS = [
  {
    address_components: [
      {
        long_name: "Paris",
        short_name: "Paris",
        types: ["locality", "political"],
      },
      {
        long_name: "Paris",
        short_name: "Paris",
        types: ["administrative_area_level_2", "political"],
      },
      {
        long_name: "Île-de-France",
        short_name: "IDF",
        types: ["administrative_area_level_1", "political"],
      },
      {
        long_name: "France",
        short_name: "FR",
        types: ["country", "political"],
      },
    ],
    formatted_address: "Paris, France",
    geometry: {
      location: {
        lat: 48.8575475,
        lng: 2.3513765,
      },
    },
    place_id: "ChIJD7fiBh9u5kcRYJSMaMOCCwQ",
    html_attributions: [],
  },
  {
    address_components: [
      {
        long_name: "New York City",
        short_name: "NYC",
        types: ["locality", "political"],
      },
      {
        long_name: "New York City",
        short_name: "NYC",
        types: ["administrative_area_level_1", "political"],
      },
      {
        long_name: "United States",
        short_name: "US",
        types: ["country", "political"],
      },
    ],
    formatted_address: "New York City, United States",
    geometry: {
      location: {
        lat: 40.7127753,
        lng: -74.0059728,
      },
    },
    place_id: "ChIJOwg_06VPwokRYv534QaPC8g",
    html_attributions: [],
  },
  {
    address_components: [
      {
        long_name: "London",
        short_name: "London",
        types: ["locality", "political"],
      },
      {
        long_name: "London",
        short_name: "London",
        types: ["postal_town"],
      },
      {
        long_name: "Greater London",
        short_name: "Greater London",
        types: ["administrative_area_level_2", "political"],
      },
      {
        long_name: "England",
        short_name: "England",
        types: ["administrative_area_level_1", "political"],
      },
      {
        long_name: "United Kingdom",
        short_name: "GB",
        types: ["country", "political"],
      },
    ],
    formatted_address: "London, United Kingdom",
    geometry: {
      location: {
        lat: 51.5072178,
        lng: -0.1275862,
      },
    },
    place_id: "ChIJdd4hrwug2EcRmSrV3Vo6llI",
    html_attributions: [],
  },
  {
    address_components: [
      {
        long_name: "Dubai",
        short_name: "Dubai",
        types: ["locality", "political"],
      },
      {
        long_name: "Dubai",
        short_name: "Dubai",
        types: ["administrative_area_level_1", "political"],
      },
      {
        long_name: "United Arab Emirates",
        short_name: "AE",
        types: ["country", "political"],
      },
    ],
    formatted_address: "Dubai, United Arab Emirates",
    geometry: {
      location: {
        lat: 25.2048493,
        lng: 55.2707828,
      },
    },
    place_id: "ChIJRcbZaklDXz4RYlEphFBu5r0",
    html_attributions: [],
  },
];

export const CURRENCIES = [
  {
    label: "France (₣)",
    value: "France",
  },
  {
    label: "Australian dollar ($) ",
    value: "AUD",
  },
  {
    label: "Euro (€) ",
    value: "EUR",
  },
  {
    label: "Pound (£)",
    value: "POUND",
  },
  {
    label: "Rupee (₹)",
    value: "RUPEE",
  },
];
export const TRANSPORTATION = [
  {
    label: "Uber/Taxi",
    value: "Uber/Taxi",
  },
  {
    label: "Walking",
    value: "Walking",
  },
  {
    label: "Public Transport",
    value: "Public Transport",
  },
  {
    label: "Rental Car",
    value: "Rental Car",
  },
];
export const VACATION_TYPE = [
  {
    label: "Museums",
    value: "Museums",
  },
  {
    label: "Outdoor Activities",
    value: "Outdoor Activities",
  },
  {
    label: "Historical",
    value: "Historical",
  },
  {
    label: "Food",
    value: "Food",
  },
];
export const DIETRY = [
  {
    label: "Halal",
    value: "Halal",
  },
  {
    label: "Vegan/Vegetarian",
    value: "Vegan/Vegetarian",
  },
  {
    label: "Pescatarian",
    value: "Pescatarian",
  },
  {
    label: "None",
    value: "None",
  },
];
export const DEMO_DATA = [
  {
    activity_name:
      "Semi-Private Louvre Masterpieces with Reserved Entrance Time:63697P1",
    activity_type: "museum",
    budget: 172.43,
    date: "2024-06-01",
    duration: 150,
    geolocation: [48.8611473, 2.33802768704666],
    time_from: "13:30",
    time_until: "16:00",
    transportation: "Walk",
    viator_input: true,
  },
  {
    activity_name: "Dinner at Le Jules Verne",
    activity_type: "food",
    budget: 100,
    date: "2024-06-01",
    geolocation: [48.85834334891665, 2.29457912982471],
    time_from: "16:30",
    time_until: "17:30",
    transportation: "Walk",
    viator_input: false,
  },
  {
    activity_name:
      "Parisian Life Style Private Photo Shoot at Eiffel Tower:100972P3",
    activity_type: "photo shoot",
    budget: 135.56,
    date: "2024-06-02",
    duration: 60,
    geolocation: [48.8582599, 2.2945006358633115],
    time_from: "10:00",
    time_until: "11:00",
    transportation: "Public Transport",
    viator_input: true,
  },
  {
    activity_name: "Explore Orsay Museum",
    activity_type: "museum",
    budget: 50,
    date: "2024-06-02",
    geolocation: [48.86024304883748, 2.3276412274253557],
    time_from: "11:30",
    time_until: "13:30",
    transportation: "Public Transport",
    viator_input: false,
  },
  {
    activity_name: "Lunch at Le Meurice Alain Ducasse",
    activity_type: "food",
    budget: 200,
    date: "2024-06-02",
    geolocation: [48.868197967761304, 2.3280537928718994],
    time_from: "14:00",
    time_until: "15:30",
    transportation: "Public Transport",
    viator_input: false,
  },
  {
    activity_name: "Visit Centre Pompidou",
    activity_type: "museum",
    budget: 40,
    date: "2024-06-03",
    geolocation: [48.86106425756541, 2.358422302058984],
    time_from: "10:00",
    time_until: "12:00",
    transportation: "Public Transport",
    viator_input: false,
  },
  {
    activity_name: "Lunch at Epicure",
    activity_type: "food",
    budget: 180,
    date: "2024-06-03",
    geolocation: [48.871758380731144, 2.31667574586854],
    time_from: "12:30",
    time_until: "14:00",
    transportation: "Public Transport",
    viator_input: false,
  },
  {
    activity_name: "Lunch at Guy Savoy",
    activity_type: "food",
    budget: 250,
    date: "2024-06-10",
    geolocation: [48.85701547410818, 2.3392274372498583],
    time_from: "12:00",
    time_until: "14:30",
    transportation: "Public Transport",
    viator_input: false,
  },
  {
    activity_name: "visit Musée Rodin",
    activity_type: "museum",
    budget: 30,
    date: "2024-06-10",
    geolocation: [48.85640717550721, 2.317892828829468],
    time_from: "15:00",
    time_until: "16:00",
    transportation: "Public Transport",
    viator_input: false,
  },
  {
    activity_name: "leisure walk to the airport",
    activity_type: "leisure",
    budget: 0,
    date: "2024-06-10",
    geolocation: [48.85640717550721, 2.317892828829468],
    time_from: "16:30",
    time_until: "18:30",
    transportation: "Public Transport",
    viator_input: false,
  },
];
export const DEMO_PAYLOAD = {
  destination: "Paris, France",
  dates: {
    from: "2024-05-15T18:30:00.000Z",
    to: "2024-05-22T18:30:00.000Z",
  },
  budget: {
    currency: {
      symbol: "€",
      name: "Euro",
      symbol_native: "€",
      decimal_digits: 2,
      rounding: 0,
      code: "EUR",
      name_plural: "euros",
    },
    amount: "1200",
  },
  transportationOptions: {
    "Uber/Taxi": false,
    Walking: true,
    "Public Transport": false,
    "Rental Car": true,
  },
  vacationType: {
    Museums: false,
    "Outdoor Activities": true,
    Historical: false,
    Food: true,
  },
  dietaryRestrictions: {
    Halal: false,
    "Vegan/Vegetarian": true,
    Pescatarian: false,
    None: false,
  },
  stayDetails: "Paris Square, Amman, Jordan",
  timeOfArrival: 5,
  timeOfDeparture: 10,
};
export const DEMO_CHECKOUT_DATA = [
  {
    date: "2024-05-29",
    time_from: "10:00",
    time_until: "11:30",
    duration: 90,
    activity_type: "Tour",
    activity_name: "Semi-Private Louvre Masterpieces with Reserved Entrance",
    budget: 175.78,
    area: "Louvre Museum",
    viatorInput: false,
    geolocation: [48.85834334891665, 2.29457912982471],
    tableId: "2024-05-29",
  },
  {
    date: "2024-05-29",
    time_from: "13:00",
    time_until: "14:00",
    duration: 60,
    activity_type: "Activity",
    activity_name: "Photo session in Paris",
    budget: 64.67,
    area: "Eiffel Tower",
    viatorInput: true,
    geolocation: [48.8582599, 2.2945006358633115],
    tableId: "2024-05-29",
  },
];
