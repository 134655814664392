import React, { useState } from "react";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

const CustomSelect = ({
  maxSelect,
  isLoading,
  ref,
  inputClass,
  isCreatable,
  placeholder,
  options,
  onChange,
  closeMenuOnSelect,
  formatCreateLabel,
  onCreateOption,
  onInputChange,
  getOptionLabel,
  getOptionValue,
  value,
  isMulti,
  isCenter,
  baseClass,
  isLazy,
  hideArrow,
  isPlaceholderLight,
  ...restSelectProp
}) => {
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (newValue) => {
    if (isLazy) {
      setInputValue(newValue);
    }
    if (onInputChange) {
      onInputChange(newValue);
    }
  };

  const filteredOptions = inputValue ? options : [];
  if (isCreatable) {
    return (
      <div className={`w-full flex flex-col items-start gap-1`}>
        <CreatableSelect
          ref={ref}
          unstyled
          value={value}
          options={isLazy ? filteredOptions : options}
          maxMenuHeight={150}
          isOptionDisabled={() => value?.length >= maxSelect}
          isLoading={isLoading}
          placeholder={placeholder || "Select.."}
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue}
          onInputChange={handleInputChange}
          onCreateOption={onCreateOption}
          formatCreateLabel={formatCreateLabel}
          className={`w-full border border-theme-black rounded-lg flex items-center justify-between text-xs sm:text-xs ${inputClass}`}
          classNames={{
            input: () => "[&_input:focus]:ring-0",
            control: () => "w-full h-full px-3 py-3 md:px-4 md:py-3 rounded-lg",
            valueContainer: () => "gap-1",
            multiValue: () =>
              "bg-theme-primary px-2 py-1 rounded-md text-theme-primary bg-opacity-10 gap-2 text-xs capitalize",
            menu: () =>
              "rounded-lg bg-theme-white border border-theme-primary border-opacity-10 p-3 mt-0.5 shadow-xl",
            option: ({ isFocused, isSelected }) => {
              if (isSelected) {
                return "bg-theme-primary text-theme-white !text-sm px-2.5 py-2.5 rounded-md cursor-pointer";
              } else if (isFocused) {
                return "cursor-pointer bg-theme-primary/10 transition-all my-0.5 !text-sm px-2.5 py-2.5 rounded-md ";
              } else {
                return "hover:bg-theme-primary hover:bg-opacity-5 transition-all my-0.5 hover:text-theme-primary text-sm px-2.5 py-2.5 rounded-md cursor-pointer !text-sm";
              }
            },
            dropdownIndicator: () =>
              "w-4 h-4 md:w-auto md:h-auto flex items-center justify-center",
          }}
          {...restSelectProp}
          closeMenuOnSelect={closeMenuOnSelect || false}
          isMulti={isMulti || false}
          onChange={(e) => {
            onChange(e);
          }}
        />
      </div>
    );
  } else {
    return (
      <div className={`w-full flex flex-col items-start gap-1`}>
        {/* {console.log("inputValue?.length", isLazy && inputValue?.length === 0)} */}
        <Select
          ref={ref}
          unstyled
          value={value}
          options={isLazy ? filteredOptions : options}
          maxMenuHeight={150}
          isOptionDisabled={() => value?.length >= maxSelect}
          isLoading={isLoading}
          placeholder={placeholder || "Select.."}
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue}
          onInputChange={handleInputChange}
          className={`w-full border border-theme-black rounded-lg flex items-center justify-between text-xs sm:text-xs ${inputClass}`}
          classNames={{
            input: () => "[&_input:focus]:ring-0",
            control: () =>
              "w-full h-full px-3 py-3 md:px-4 md:py-2.5 rounded-lg",
            valueContainer: () => "gap-1",
            multiValue: () =>
              "bg-theme-primary px-2 py-1 rounded-md text-theme-primary bg-opacity-10 gap-2 text-xs capitalize",
            menu: () =>
              `rounded-lg bg-theme-white border border-theme-primary border-opacity-10 p-2 mt-0.5 shadow-xl ${
                isCenter ? "text-center" : ""
              } ${isLazy && inputValue?.length === 0 ? "hidden" : ""}`,
            placeholder: () =>
              `${
                isPlaceholderLight
                  ? "text-theme-black/60"
                  : "text-theme-black/100"
              }`,
            option: ({ isFocused, isSelected }) => {
              if (isSelected) {
                return "bg-theme-primary text-theme-white !text-xs px-2.5 py-2.5 rounded-md cursor-pointer";
              } else if (isFocused) {
                return "cursor-pointer bg-theme-primary/10 transition-all my-0.5 !text-xs px-2.5 py-2.5 rounded-md ";
              } else {
                return `hover:bg-theme-primary hover:bg-opacity-5 transition-all my-0.5 hover:text-theme-primary px-2.5 py-2.5 rounded-md cursor-pointer !text-xs`;
              }
            },
            dropdownIndicator: () =>
              `w-3 h-3 md:w-auto md:h-auto items-center justify-center ${
                hideArrow ? "!hidden" : "flex"
              }`,
          }}
          {...restSelectProp}
          closeMenuOnSelect={closeMenuOnSelect || false}
          isMulti={isMulti || false}
          onChange={(e) => {
            onChange(e);
          }}
        />
      </div>
    );
  }
};

export default CustomSelect;
