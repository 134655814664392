import React from 'react'
import NoDataVector from "assets/images/no-data.svg"
import { ROUTES } from 'constants/allRoutes'
import { useNavigate } from 'react-router-dom'
import { BsArrowLeft } from 'react-icons/bs'

const NoData = () => {
  const navigate = useNavigate()
  const handleRedirect = () => {
    navigate(ROUTES.home);
    navigate(0);
  }
  return (
    <div className='theme-container w-full h-full flex flex-col items-center justify-center gap-1 text-center'>
      <img src={NoDataVector} alt="no data found" className='w-32 sm:w-40 md:w-48 lg:w-52' />
      <h3 className='text-theme-primary font-bold text-lg md:text-xl lg:text-2xl'>OOPS! NO DATA FOUND!</h3>
      <button type='button' onClick={handleRedirect} className='flex items-center gap-2 mt-5 bg-theme-primary border border-theme-primary/10 px-4 py-2.5 md:py-3 rounded-md text-theme-primary-opacity text-xs sm:text-sm'>
        <>
          <BsArrowLeft className='text-base md:text-lg' />
          Return to Home
        </>
      </button>
    </div>
  )
}

export default NoData