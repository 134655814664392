import React, { createContext, useContext, useState } from 'react';

const ProfileContext = createContext();

export const useProfileContext = () => useContext(ProfileContext);

export const ProfileProvider = ({ children }) => {
  const [profile, setProfile] = useState(null);
  const [profileLoading, setProfileLoading] = useState(false);

  return (
    <ProfileContext.Provider value={{
      profile, setProfile, profileLoading, setProfileLoading }}>
      {children}
    </ProfileContext.Provider>
  );
};
