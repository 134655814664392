import Home from "pages/Home";
import { Route, Routes } from "react-router-dom";
import { ROUTES } from "./constants/allRoutes";
import Itinerary from "./pages/Itinerary";
import NotFoundPage from "./pages/NotFoundPage";
import Checkout from "./pages/Checkout";

function App() {
  return (
    <main className="w-full">
      <Routes>
        <Route path={ROUTES.home} element={<Home />} />
        <Route path={ROUTES.itinerary} element={<Itinerary />} />
        <Route path={ROUTES.checkout} element={<Checkout />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </main>
  );
}

export default App;
