import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { BASE_URL } from "../constants";
import axios from "axios";
import toast from "react-hot-toast";
import moment from "moment";
import { useScheduleContext } from "provider/ScheduleContext";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "constants/allRoutes";
import CommonForm from "components/custom/CommonForm";
import HeroBanner from "components/custom/HeroBanner";
import { useErrorPageContext } from "provider/ErrorPageContexr";
import { formatTime } from "constants/utils";
import LoadingScreenPortal from "./Loading";

const Home = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const {
    setScheduleData,
    setSchedulePayload,
    isScheduleLoading,
    setIsScheduleLoading,
  } = useScheduleContext();
  const { setIsErrorPage } = useErrorPageContext();
  useEffect(() => {
    setIsErrorPage(false);
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    register,
    control,
    handleSubmit,
    reset,
    getValues,
    setValue,
    setError,
    watch,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: {},
  });

  const onSubmit = async (data) => {
    console.log("data on submit", data);
    try {
      setLoading(true);
      setIsScheduleLoading(true);
      const startDate = moment(data?.dates?.from).format("YYYY-MM-DD");
      const endDate = moment(data?.dates?.to).format("YYYY-MM-DD");
      const arrivalTime = formatTime(data?.timeOfArrival);
      const departureTime = formatTime(data?.timeOfDeparture);
      const paylodData = {
        startDate: startDate,
        endDate: endDate,
        currency: data?.budget?.currency?.code,
        arrivalTime: arrivalTime,
        departureTime: departureTime,
        budget: Number(data?.budget?.amount),
        // Change the location to below code when Dropdown is changed with Google Autocomplete
        // location: data?.destination,
        location: data?.destination?.formatted_address,
        stayDetails: data?.stayDetails,
        dietaryRestrictions: data?.dietaryRestrictions,
        transportationOptions: data?.transportationOptions,
        vacationType: data?.vacationType,
      };
      console.log("payload data: ", paylodData);
      await axios
        .post(`${BASE_URL}/createSchedule`, paylodData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer aBc123dEfGhIjKlMnOpQrStUvWxYz",
          },
        })
        .then((response) => {
          setScheduleData(response?.data?.schedule);
          setSchedulePayload(data);
          navigate(ROUTES.itinerary);
        });
    } catch (e) {
      setIsScheduleLoading(false);
      toast.error(
        e?.response?.data?.errors?.location ||
          e?.message ||
          "Something went wrong when submitting data."
      );
    } finally {
      setLoading(false);
      setIsScheduleLoading(false);
    }
  };
  const watchDestination = watch("destination");
  const watchDates = watch("dates");
  const watchBudgetCurrency = watch("budget.currency");
  const watchBudgetAmount = watch("budget.amount");

  return (
    <>
      {<LoadingScreenPortal isOpen={isScheduleLoading} />}
      <div className="w-full min-h-screen bg-theme-white bg-repeat-y bg-left-top bg-cover flex flex-col items-center gap-7 pb-7">
        <HeroBanner />
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="theme-container w-full flex flex-col gap-7 items-center"
        >
          <CommonForm
            loading={loading}
            register={register}
            control={control}
            reset={reset}
            setValue={setValue}
            errors={errors}
            getValues={getValues}
            isShowFilters={
              watchDestination &&
              watchDates &&
              watchBudgetCurrency &&
              watchBudgetAmount
            }
          />
        </form>
      </div>
    </>
  );
};

export default Home;
