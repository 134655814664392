import React from 'react';
import { Slider } from "@nextui-org/react";
import { formatTime } from 'constants/utils';

const TimeRangeSlider = ({ onChange, value, label }) => {

  return (
    <div className="flex flex-col gap-2 w-full h-full max-w-md items-start justify-center">
      <Slider
        label={label}
        step={1}
        showTooltip={true}
        tooltipProps={{
          content: [`${formatTime(value)}`],
          classNames: {
            base: "before:bg-theme-primary",
            content: "bg-theme-primary",
            arrow: "!bg-theme-primary !text-theme-primary"
          }
        }}
        maxValue={24}
        showOutline={true}
        color='primary'
        minValue={0}
        value={value}
        onChange={(newValue) => {
          onChange(newValue);
        }} 
        getValue={formatTime}
        className="w-full"
        classNames={{
          value: "text-transparent",
          label: "text-sm sm:text-base font-bold",
          trackWrapper: "mt-2",
          filler: "bg-theme-primary h-1.5",
          track: "bg-theme-primary/10 h-1.5",
          thumb: "bg-theme-primary",
          step: "bg-theme-primary"
        }}
      />
      <p className="text-default-500 text-xs">
        Selected Time: {formatTime(value)}
      </p>
    </div>
  );
};

export default TimeRangeSlider;
