import React from 'react'
import Header from './Header'
import Footer from './Footer'
import { useErrorPageContext } from 'provider/ErrorPageContexr';

const Layout = ({ children }) => {
  const { isErrorPage } = useErrorPageContext();
if (isErrorPage) {
    return (
      <>
        {children}
      </>
    )
  } else {
    return (
      <>
        <div className='w-full flex flex-col items-start'>
          <Header />
          {children}
        </div>
        <Footer />
      </>
    )
  }
}

export default Layout