import React from 'react'
import LogoIcon from "assets/images/logo-icon.png"
import { SOCIAL_LINKS } from 'constants/allRoutes'
import { FaFacebookF, FaInstagram, FaXTwitter, FaLinkedinIn } from "react-icons/fa6";

const Footer = () => {
  return (
    <div className='w-full bg-theme-primary-opacity'>
      <div className='theme-container w-full flex flex-wrap items-center gap-x-10 gap-y-3 justify-center md:justify-between py-4'>
        <div className='flex items-center gap-2'>
          <img src={LogoIcon} className='w-8 sm:w-11' alt='planned for you logo' />
          <p className='capitalize text-theme-primary font-bold sm:text-lg'>Planned For You</p>
        </div>
        <div className='flex items-center flex-wrap justify-center gap-4'>
          <p className='text-theme-black text-sm md:text-base'>Follow us on:</p>
          <div className='flex items-center gap-2.5'>
            <a href={SOCIAL_LINKS.facebook} target='_blank' className='social-link' rel="noreferrer">
              <FaFacebookF/>
            </a>
            <a href={SOCIAL_LINKS.instagram} target='_blank' className='social-link' rel="noreferrer">
              <FaInstagram/>
            </a>
            <a href={SOCIAL_LINKS.twitter} target='_blank' className='social-link' rel="noreferrer">
              <FaXTwitter/>
            </a>
            <a href={SOCIAL_LINKS.linkedink} target='_blank' className='social-link' rel="noreferrer">
              <FaLinkedinIn/>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer