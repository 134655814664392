import React from "react";

const CustomInput = ({
  label,
  type,
  required,
  placeholder,
  name,
  inputMode,
  register,
  inputClass,
  defaultValue,
  baseClass,
  validation,
  error,
  ref,
  caption,
  onChange,
  disabled,
  hideLable
}) => {
  const setRegister = register && register(name, validation);
  const handleScroll = (event) => {
    if (type === 'number') {
      event.preventDefault();
      event.target.blur()
    }
  };
  return (
    <div
      className={`w-full h-auto flex flex-col items-start gap-2 ${baseClass ? baseClass : ""
        }`}
    >
      {label && (
        <p className={`text-sm sm:text-base font-bold ${hideLable ? "text-transparent" : ""}`}>
          {label} 
        </p>
      )}
      <div className="w-full flex flex-col items-start gap-1">
        <input
          disabled={disabled}
          name={name}
          type={type}
          ref={ref}
          inputMode={inputMode}
          placeholder={placeholder}
          defaultValue={defaultValue}
          {...setRegister}
          onChange={(e) => {
            setRegister.onChange(e);
            onChange && onChange(e);
          }}
          onWheel={handleScroll}
          className={`bg-transparent border border-theme-black outline-0 w-full px-3 py-3 md:px-4 md:py-3 focus:ring-theme-secondary focus:ring-0 focus-visible:ring-0 focus-visible:outline-none rounded-lg text-xs sm:text-xs disabled:cursor-not-allowed disabled:border-theme-dark-gray disabled:border-opacity-50 disabled:text-theme-dark-gray disabled:text-opacity-50 placeholder-theme-black ${inputClass}`}
        />
        {caption && <p className="text-xs sm:text-sm">{caption}</p>}
      </div>
      {error && error?.message && (
        <span className="text-red-500 text-xs md:text-xs">
          {error?.message}
        </span>
      )}
    </div>
  );
};

export default CustomInput;