
import * as React from "react"
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import { ArrowUpDown, ChevronDown, MoreHorizontal } from "lucide-react"

import { Button } from "components/ui/button"
import { Checkbox } from "components/ui/checkbox"
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu"
import { Input } from "components/ui/input"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "components/ui/table"
import { DisLikeIcon, LikeIcon, RefreshIcon } from "constants/icons"
import { useRef } from "react"
import useWindowSize from "hooks/useWindow"
import { useEffect } from "react"


const data = [
  {
    activity_type: "Other",
    activity_name: "Arrival & Check-in",
    budget: "$30",
    time: "10:00",
    duration: "30 min",
    transportation: "Walk",
  },
]

export const columns = [
  {
    id: "select",
    header: ({ table }) => (
      <Checkbox
        checked={
          table.getIsAllPageRowsSelected() ||
          (table.getIsSomePageRowsSelected() && "indeterminate")
        }
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label="Select row"
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  // {
  //   accessorKey: "time_from",
  //   header: "Time from",
  //   cell: ({ row }) => {
  //     return (
  //       <div className="capitalize">{row.getValue("time_from")}</div>
  //   )},
  // },
  // {
  //   accessorKey: "time_until",
  //   header: "Time until",
  //   cell: ({ row }) => {
  //     return (
  //       <div className="capitalize">{row.getValue("time_until")}</div>
  //   )},
  // },
  {
    accessorKey: "time", // New accessor key for the combined "time" field
    header: "Time", // Updated header label
    cell: ({ row }) => {
      // Get the values of "time_from" and "time_until"
      const timeFrom = row.original.time_from;
      const timeUntil = row.original.time_until;

      // Check if both values are defined and not empty
      if (timeFrom && timeUntil) {
        // Combine "time_from" and "time_until" to display as "time"
        return (
          <div className="capitalize whitespace-nowrap">
            {`${timeFrom} - ${timeUntil}`}
          </div>
        );
      } else {
        // Handle case where either value is undefined or empty
        return (
          <div className="capitalize">N/A</div>
        );
      }
    },
  },
  {
    accessorKey: "duration",
    header: "Duration",
    cell: ({ row }) => (
      <div className="capitalize">{row.getValue("duration") || "-"}</div>
    ),
  },
  {
    accessorKey: "activity_type",
    header: "Activity Type",
    cell: ({ row }) => (
      <div className="capitalize">{row.getValue("activity_type") || "-"}</div>
    ),
  },
  {
    accessorKey: "activity_name",
    header: "Activity",
    cell: ({ row }) => (
      <div className="capitalize">{row.getValue("activity_name") || "-"}</div>
    ),
  },
  {
    accessorKey: "transportation",
    header: "Transportation",
    cell: ({ row }) => (
      <div className="capitalize">{row.getValue("transportation") || "-"}</div>
    ),
  },
  {
    accessorKey: "budget",
    header: "Budget",
    cell: ({ row }) => (
      <div className="capitalize">{row.getValue("budget") || "-"}</div>
    ),
  },
  {
    id: "actions",
    header: "Actions",
    enableHiding: false,
    cell: ({ row }) => {
      return (
        <div className="flex items-center">
          <button className="h-8 w-8 p-0 bg-none">
            <RefreshIcon/>
          </button>
          <button className="h-8 w-8 p-0 bg-none">
            <LikeIcon/>
          </button>
          <button className="h-8 w-8 p-0 bg-none">
            <DisLikeIcon/>
          </button>
        </div>
      )
    },
  },
]


export function DataTableDemo({ tableData, tableId, heading, setSelectedItems, selectedItems, showDropdown, columnVisibility, setColumnVisibility, columnSelectActive, setColumnSelectActive }) {
  const [sorting, setSorting] = React.useState([])
  const [columnFilters, setColumnFilters] = React.useState([])
  const [rowSelection, setRowSelection] = React.useState({})
  const previousSelectedItemsRef = useRef(selectedItems)
  // const [columnVisibility, setColumnVisibility] = React.useState({})
  // const [columnSelectActive, setColumnSelectActive] = React.useState(false)
  const dropdownRef = useRef(null);
  const [width] = useWindowSize()
  const table = useReactTable({
    data: tableData,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  })

  useEffect(() => {
    const selectedRows = table.getSelectedRowModel().rows.map((row) => ({
      ...row.original,
      tableId,
    }));
    setSelectedItems((prevItems) => {
      // Filter out items from the same tableId
      const filteredItems = prevItems.filter(item => item.tableId !== tableId);
      // Merge the new selections with the previous ones
      return [...filteredItems, ...selectedRows];
    });
  }, [rowSelection, setSelectedItems, tableId]);

  // Function to toggle the visibility of the dropdown menu
  const toggleColumnSelect = () => {
    setColumnSelectActive(!columnSelectActive);
  };

  // Function to handle clicks outside the dropdown menu
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setColumnSelectActive(false);
    }
  };

  // Effect to add event listener when component mounts
  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="w-full">
      <div className="flex sm:flex-row flex-col items-start sm:items-center justify-between w-full gap-4 flex-wrap pb-3">
        <p className='text-base md:text-lg lg:text-lg xl:text-xl font-bold'>{heading}</p>
        {
          showDropdown && (
            <DropdownMenu open={columnSelectActive}>
              <DropdownMenuTrigger>
                <Button type="button" onClick={toggleColumnSelect} variant="outline" className="ml-auto bg-theme-white">
                  Columns <ChevronDown className="ml-2 h-4 w-4" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align={`${width > 640 ? "end" : "start"}`} ref={dropdownRef}>
                {table
                  .getAllColumns()
                  .filter((column) => column.getCanHide())
                  .map((column) => {
                    return (
                      <DropdownMenuCheckboxItem
                        key={column.id}
                        className="capitalize"
                        checked={column.getIsVisible()}
                        onCheckedChange={(value) =>
                          column.toggleVisibility(!!value)
                        }
                      >
                        {column.id}
                      </DropdownMenuCheckboxItem>
                    )
                  })}
              </DropdownMenuContent>
            </DropdownMenu>
          )
        }
      </div>
      <div className="rounded-md border p-4 shadow-md bg-theme-white">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                    </TableHead>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel()?.rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns?.length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className="flex items-center justify-end space-x-2 py-4">
        <div className="flex-1 text-sm text-muted-foreground">
          {table.getFilteredSelectedRowModel().rows?.length} of{" "}
          {table.getFilteredRowModel().rows?.length} row(s) selected.
        </div>
        {/* <div className="space-x-2">
          <Button
            variant="outline"
            size="sm"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            Previous
          </Button>
          <Button
            variant="outline"
            size="sm"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            Next
          </Button>
        </div> */}
      </div>
    </div>
  )
}
