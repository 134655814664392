import { ROUTES } from 'constants/allRoutes'
import React, { useEffect } from 'react'
import { BsArrowLeft } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import ErrorImg from 'assets/images/404.svg';
import { useErrorPageContext } from 'provider/ErrorPageContexr';

const NotFoundPage = () => {
  const { setIsErrorPage } = useErrorPageContext();
  useEffect(() => {
    setIsErrorPage(true)
  }, [])
  return (
    <div className='theme-container p-8 min-h-screen w-full h-full flex flex-col items-center justify-center gap-1 text-center'>
      <img src={ErrorImg} alt="no data found" className='w-40 sm:w-[160] md:w-[220px] lg:w-[280px] xl:w-[340px]' />
      <h3 className='text-theme-primary font-bold text-xl sm:text-2xl md:text-3xl lg:text-4xl mt-4'>OOPS! PAGE NOT FOUND!</h3>
      <Link to={ROUTES.home} className='flex items-center gap-2 mt-5 md:mt-10 bg-theme-primary border border-theme-primary/10 px-4 py-2.5 md:py-3 rounded-md text-theme-primary-opacity text-sm sm:text-base'>
        <>
          <BsArrowLeft className='text-lg md:text-xl' />
          Return to Home
        </>
      </Link>
    </div>
  )
}

export default NotFoundPage