import React, { useEffect, useState } from 'react'
import LogoIcon from "assets/images/logo-icon.png"
import { Dialog, DialogTrigger, DialogContent } from 'components/ui/dialog'
import GoogleLogin from 'components/custom/GoogleLogin'
import { useGoogleLogin } from '@react-oauth/google';
import { Link } from 'react-router-dom';
import toast from 'react-hot-toast';
import axios from 'axios';
import { API_BASE_URL } from 'constants';
import { ROUTES } from 'constants/allRoutes';
import { Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, Avatar } from "@nextui-org/react";
import { useProfileContext } from 'provider/ProfileContext';

const Header = () => {
  const accessToken = localStorage.getItem('accessToken');
  const [loading, setLoading] = useState(false);
  const [isOpenLoginModal, setIsOpenLoginModal] = useState(false);
  const [isOpenSignupModal, setIsOpenSignupModal] = useState(false);
  const { profile, setProfile, profileLoading, setProfileLoading } = useProfileContext();
  // const navigate = useNavigate()
  const googleOAuthLogin = useGoogleLogin({
    onSuccess: async tokenResponse => {
      if (tokenResponse?.access_token) {
        setLoading(true)
        try {
          await axios.post(`${API_BASE_URL}/savegooglelogin`, {
            token: tokenResponse?.access_token
          }).then((response) => {
            const userInfo = {
              id: response?.data?.id,
              name: response?.data?.name,
              email: response?.data?.email,
              picture: response?.data?.picture,
            };
            localStorage.setItem('accessToken', response?.data?.token);
            localStorage.setItem('userInfo', JSON.stringify(userInfo));
            getMyProfile(response?.data?.token)
          })
        } catch (error) {
          toast.error(error?.error_description || error?.error || "Something went wrong while saving user's data")
        } finally {
          setLoading(false)
        }
      } else {
        toast.error("Google Access token not found.")
      }
    },
    onError: error => toast.error(error?.error_description || error?.error || "Something went wrong with google login")
  });
  const handleLogout = async () => {
    try {
      axios.post(`${API_BASE_URL}/signout`, null, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      // Clear local storage
      localStorage.removeItem('accessToken');
      setIsOpenLoginModal(false)
      setIsOpenSignupModal(false)
      // navigate(0);
      // Optionally, perform additional logout operations like clearing cookies, etc.
    } catch (error) {
      console.error("Logout Error:", error);
      toast.error("Failed to log out. Please try again later.");
    }
  }

  const getMyProfile = async (token) => {
    setProfileLoading(true)
    try {
      await axios.post(`${API_BASE_URL}/myprofile`, null, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).catch((e) => {
        toast.error(e?.message || "something went wrong!")
        localStorage.removeItem('accessToken');
        // navigate(0);
      }).then((response) => {
        if (response?.data?.id) {
          setProfile(response?.data)
        } else {
          toast.error("Something went wrong with getting user'r details!")
          localStorage.removeItem('accessToken');
          // navigate(0);
        }
      })
    } catch (error) {
      toast.error(error?.error_description || error?.error || "Something went wrong while fething user's profile")
      localStorage.removeItem('accessToken');
      // navigate(0);
    } finally {
      setProfileLoading(false)
    }
  }

  useEffect(() => {
    if (accessToken && !profile?.id && !profileLoading) {
      getMyProfile(accessToken)
    }
  }, [accessToken, profile, profileLoading]);

  return (
    <div className='w-full bg-theme-white sticky top-0 z-30'>
      <div className='theme-container w-full flex items-center gap-6 justify-between py-4'>
        <Link to="/">
          <div className='flex items-center gap-1.5 md:gap-2.5'>
            <img src={LogoIcon} className='w-[35px] sm:w-[50px] md:w-[40px] lg:w-[45px]' alt='planned for you logo' />
            <p className='block capitalize text-theme-primary font-bold text-sm sm:text-lg md:text-xl whitespace-nowrap'>Planned For You</p>
          </div>
        </Link>
        <div className='flex items-center gap-1 font-semibold text-theme-black'>
          {
            accessToken && profile?.id && !profileLoading ? (
              <>
                <Link className='nav-link' to={ROUTES.home}>Home</Link>
                <Link className='nav-link' to={ROUTES.trips}>Trips</Link>
                <Dropdown placement="bottom-end">
                  <DropdownTrigger>
                    <Avatar
                      isBordered
                      as="button"
                      radius="md"
                      className="transition-transform ml-4"
                      src={profile?.picture}
                    />
                  </DropdownTrigger>
                  <DropdownMenu aria-label="Profile Actions" variant="flat">
                    <DropdownItem key="profile" className="h-14 gap-2">
                      <p className="font-semibold">Signed in as</p>
                      <p className="font-semibold">{profile?.name}</p>
                    </DropdownItem>
                    <DropdownItem key="profile">
                      Profile
                    </DropdownItem>
                    <DropdownItem key="settings">
                      Settings
                    </DropdownItem>
                    <DropdownItem key="logout" color="danger" onClick={handleLogout}>
                      Log Out
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </>
            ): (
              <>
                  <Dialog open={isOpenLoginModal}>
                  <DialogTrigger onClick={() => setIsOpenLoginModal(true)}>
                    <p className='nav-link'>Login</p>
                    </DialogTrigger>
                    
                    <DialogContent close={() => setIsOpenLoginModal(false)} className="max-w-sm sm:max-w-2xl md:max-w-3xl lg:max-w-[930px] rounded-xl">
                      <GoogleLogin loading={loading} setLoading={setLoading} type="login" onClick={googleOAuthLogin} />
                  </DialogContent>
                </Dialog>
                  <Dialog open={isOpenSignupModal}>
                    <DialogTrigger onClick={() => setIsOpenSignupModal(true)}>
                    <p className='nav-link'>Sign up</p>
                  </DialogTrigger>
                    <DialogContent close={() => setIsOpenSignupModal(false)} className="max-w-sm sm:max-w-2xl md:max-w-3xl lg:max-w-[930px] rounded-xl">
                      <GoogleLogin loading={loading} setLoading={setLoading} type="signup" onClick={googleOAuthLogin} />
                  </DialogContent>
                </Dialog>
              </>
            )
          }
        </div>
      </div>
    </div>
  )
}

export default Header