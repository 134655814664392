import { AdvancedMarker, InfoWindow, useAdvancedMarkerRef } from '@vis.gl/react-google-maps';
import React, { useState } from 'react'
import CUSTOM_MARKER from "assets/images/custom-marker.svg"

const CustomMarker = ({item}) => {
  const [infowindowOpen, setInfowindowOpen] = useState(false);
  const [markerRef, marker] = useAdvancedMarkerRef();
  return (
    <>
      <AdvancedMarker
        ref={markerRef}
        position={{ lat: item.geolocation[0], lng: item.geolocation[1] }}
        onClick={() => setInfowindowOpen(true)}
      >
        <img src={CUSTOM_MARKER} alt="marker" className='w-7 md:w-12 object-contain' />
      </AdvancedMarker>
      {infowindowOpen && (
        <InfoWindow
          anchor={marker}
          maxWidth={200}
          onCloseClick={() => setInfowindowOpen(false)}>
            <p>{item?.activity_name}</p>
        </InfoWindow>
      )}
    </>
  )
}

export default CustomMarker