export const VALIDATION = {
  name: {
    required: "This field is required",
    maxLength: {
      value: 15,
      message: "Name should be only 15 characters long",
    },
    pattern: {
      value: /^[a-zA-Z]+(?:\s+[a-zA-Z]+)*$/,
      message: "Only alphabets are allowed",
    },
  },
  slider: {
    pattern: {
      value: /^[^1-24]/,
      message: 'Value should be greater than 0',
    }
  },
  email: {
    required: "This field is required",
    pattern: {
      value: /^(?!.*\.{2})\S+@\S+\.\S+$/,
      message: "Please enter a valid email address",
    },
  },
  password: {
    required: "This field is required",
    minLength: {
      value: 7,
      message: "Password must be at least 7 characters long",
    },
    pattern: {
      value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{7,}$/,
      message: "Password must contain at least one uppercase letter, one lowercase letter, and one special character",
    },
  },
  required: {
    required: "This field is required",
    pattern: {
      value: /^\S(?:\s*\S)*$/,
      message: "Extra Spaces not Allowed",
    },
  },
  creditCardNumber: {
    required: "Credit card number is required",
    pattern: {
      value: /^[0-9]{13,19}$/, // Example for Visa cards
      message: "Invalid credit card number",
    },
    setValueAs: (v) => v.replace(/\s+/g, "").replace(/-/g, ""),
  },
  number: {
    pattern: {
      value: /^[0-9]+$/,
      message: "Only Numeric Value Allowed",
    },
  },
};
