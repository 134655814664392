import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import {
  BASE_URL,
  DEMO_DATA,
  DESTIATIONS,
  GOOGLE_MAPS_API_KEY,
} from "../constants";
import axios from "axios";
import toast from "react-hot-toast";
import moment from "moment";
import { useScheduleContext } from "provider/ScheduleContext";
import { DataTableDemo } from "components/custom/CustomDataTable";
import { APIProvider, Map } from "@vis.gl/react-google-maps";
import { MAP_STYLE_JSON } from "constants/mapStyle";
import CustomMarker from "components/custom/CustomMarker";
import CommonForm from "components/custom/CommonForm";
// import HeroBanner from 'components/custom/HeroBanner';
import { Accordion, AccordionItem } from "@nextui-org/react";
import NoDataVector from "assets/images/no-data.svg";
import { Link, useNavigate } from "react-router-dom";
import { ROUTES } from "constants/allRoutes";
import { BsArrowLeft } from "react-icons/bs";
import { HiArrowLongRight } from "react-icons/hi2";
import { useErrorPageContext } from "provider/ErrorPageContexr";
import { CustomScroll } from "react-custom-scroll";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "components/ui/select";
import LoadingScreenPortal from "./Loading";
import { formatTime } from "constants/utils";
import NoData from "components/custom/NoData";
import isEqual from "lodash/isEqual";

const Itinerary = () => {
  const [loading, setLoading] = useState(false);
  const { schedule, schedulePayload } = useScheduleContext();
  const { setIsErrorPage } = useErrorPageContext();
  // const [visibleTables, setVisibleTables] = useState(1);
  const [totalTables, setTotalTables] = useState(1);
  const [selectedDate, setSelectedDate] = useState(null);
  const [uniqueDates, setUniqueDates] = useState([]);
  const [columnVisibility, setColumnVisibility] = React.useState({});
  const [columnSelectActive, setColumnSelectActive] = React.useState(false);
  const navigate = useNavigate();
  const {
    setScheduleData,
    setSchedulePayload,
    isScheduleLoading,
    setIsScheduleLoading,
    checkoutItems,
    setCheckoutItems,
  } = useScheduleContext();

  useEffect(() => {
    setCheckoutItems([]);
  }, []);

  // Scroll to the top of the page when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setIsErrorPage(false);
    if (schedule && schedule.length > 0) {
      // Extract unique dates from the schedule
      const uniqueDates = Array.from(
        new Set(schedule.map((item) => item.date))
      );
      setUniqueDates(uniqueDates);
      // Set the default selected date to the first date in the uniqueDates array
      setSelectedDate(uniqueDates[0]);
    }
  }, [schedule, setIsErrorPage]);

  const defaultCenter = { lat: 48.8566, lng: 2.3522 };
  const {
    register,
    control,
    handleSubmit,
    reset,
    getValues,
    setValue,
    setError,
    watch,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: {},
  });
  const values = getValues();
  const watchDestination = watch("destination");
  const watchDates = watch("dates");
  const watchBudgetCurrency = watch("budget.currency");
  const watchBudgetAmount = watch("budget.amount");
  const watchLatestFormData = watch();

  const isPreviousPayloadSame =
    getValues() !== null && isEqual(watchLatestFormData, schedulePayload);

  useEffect(() => {
    if (schedulePayload) {
      const { destination, ...otherPayload } = schedulePayload;
      reset({
        ...otherPayload,
        // Comment out below commented code when destination dropdown is dynamic or Google AutoComplete
        // destination: schedulePayload?.destination,
        destination: DESTIATIONS.filter(
          (data) =>
            data?.formatted_address ===
            schedulePayload?.destination?.formatted_address
        )?.[0],
        stayDetails: schedulePayload?.stayDetails,
      });
    }
  }, [schedulePayload]);

  const onSubmit = async () => {
    const data = watchLatestFormData;
    try {
      setLoading(true);
      setIsScheduleLoading(true);
      const startDate = moment(data?.dates?.from).format("YYYY-MM-DD");
      const endDate = moment(data?.dates?.to).format("YYYY-MM-DD");
      const arrivalTime = formatTime(data?.timeOfArrival);
      const departureTime = formatTime(data?.timeOfDeparture);
      const paylodData = {
        startDate: startDate,
        endDate: endDate,
        arrivalTime: arrivalTime,
        departureTime: departureTime,
        currency: data?.budget?.currency?.code,
        budget: Number(data?.budget?.amount),
        location: data?.destination?.formatted_address,
        stayDetails: data?.stayDetails,
        dietaryRestrictions: data?.dietaryRestrictions,
        transportationOptions: data?.transportationOptions,
        vacationType: data?.vacationType,
      };
      await axios
        .post(`${BASE_URL}/createSchedule`, paylodData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer aBc123dEfGhIjKlMnOpQrStUvWxYz",
          },
        })
        .then((response) => {
          if (response?.data?.schedule) {
            setScheduleData(response?.data?.schedule);
            setSchedulePayload(watchLatestFormData);
            console.log("response", response?.data?.schedule?.schedule);
            toast.success("Regenerated!");
          } else {
            toast.error("Something went wrong!!");
          }
        });
    } catch (e) {
      toast.error(e?.message || "Something went wrong when submitting data.");
      setIsScheduleLoading(false);
    } finally {
      setLoading(false);
      setIsScheduleLoading(false);
    }
  };

  const groupedSchedule = {}; // Group activities by date
  schedule.forEach((activity) => {
    if (!groupedSchedule[activity.date]) {
      groupedSchedule[activity.date] = [];
    }
    groupedSchedule[activity.date].push(activity);
  });

  const renderDayTables = () => {
    // Render tables up to the totalTables value
    return Object.entries(groupedSchedule)
      .slice(0, totalTables)
      .map(([date, activities], index) => (
        <DataTableDemo
          key={date}
          tableId={date}
          tableData={activities}
          showDropdown={index === 0}
          heading={`Day ${index + 1} - ${moment(date).format("MMMM DD, YYYY")}`}
          setSelectedItems={setCheckoutItems}
          selectedItems={checkoutItems}
          columnVisibility={columnVisibility}
          setColumnVisibility={setColumnVisibility}
          columnSelectActive={columnSelectActive}
          setColumnSelectActive={setColumnSelectActive}
        />
      ));
  };

  // Render markers based on the selected date
  const renderMarkersForSelectedDate = () => {
    if (!selectedDate) return;
    const filteredSchedule = schedule.filter(
      (activity) => activity.date === selectedDate
    );
    return filteredSchedule.map((item, index) => (
      <CustomMarker item={item} key={index} />
    ));
  };

  const handleCheckout = () => {
    if (checkoutItems?.length > 0) {
      navigate(ROUTES.checkout);
    } else {
      toast.error("Please select items for checkout.");
    }
  };

  return (
    <div
      className={`w-full min-h-[calc(100vh-164px)] bg-theme-white bg-repeat-y bg-left-top bg-cover flex flex-col items-center gap-7 pb-7 ${
        schedule?.length === 0 ? "justify-center" : ""
      }`}
    >
      {<LoadingScreenPortal isOpen={isScheduleLoading} />}
      {schedule?.length > 0 ? (
        <>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="theme-container w-full flex flex-col gap-7 mt-3 items-center"
          >
            <Accordion variant="light" className="w-full">
              <AccordionItem
                key="1"
                aria-label="formAccordian"
                title="EDIT DETAILS"
                className="customAccordian "
                classNames={{
                  base: "bg-theme-primary-opacity rounded rounded-xl md:rounded-2xl border border-theme-primary/5 ",
                  heading: "px-7 py-1 font-bold",
                  title: "text-theme-primary text-base md:text-lg lg:text-xl ",
                  trigger: "py-3 lg:py-4",
                  indicator: "text-xl text-theme-primary",
                }}
              >
                <CommonForm
                  loading={loading}
                  register={register}
                  control={control}
                  reset={reset}
                  setValue={setValue}
                  errors={errors}
                  isShowFilters={
                    watchDestination &&
                    watchDates &&
                    watchBudgetCurrency &&
                    watchBudgetAmount
                  }
                  isButtonHide
                  isOtherPage
                  baseClass="!pt-1"
                  getValues={getValues}
                  values={values}
                />
              </AccordionItem>
            </Accordion>
            {!isPreviousPayloadSame && (
              <button
                disabled={loading}
                type="submit"
                className="bg-theme-primary text-theme-white rounded-md py-4 px-8 md:text-base text-sm md:px-10 lg:px-20 font-bold hover:scale-95 transition-all hover:bg-theme-black  border border-theme-primary hover:border-opacity-10 disabled:cursor-not-allowed disabled:bg-theme-primary disabled:scale-100 flex items-center gap-4 shadow-xl hover:shadow-none"
              >
                {" "}
                {loading && (
                  <svg
                    aria-hidden="true"
                    className="w-3 h-3 lg:w-5 lg:h-5 text-gray-200 animate-spin fill-theme-primary"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                )}{" "}
                Regenerate
              </button>
            )}
          </form>
          <div className="theme-container w-full">
            <div className="w-full flex flex-col items-start gap-3 lg:gap-4 text-theme-black">
              <p className="text-lg lg:text-xl xl:text-2xl font-bold">
                Your itinerary to{" "}
                {schedulePayload?.destination?.formatted_address ||
                  "Unknown City"}
              </p>
              {/* <p className='text-base lg:text-lg xl:text-xl font-bold'>About</p>
                <p className='text-sm md:text-base'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type.</p> */}
              <div className="w-full h-[500px] overflow-y-auto bg-theme-primary-opacity/20 border border-theme-primary-opacity rounded-xl overflow-hidden">
                <CustomScroll
                  heightRelativeToParent="100%"
                  className="flex-1"
                  handleClass="!bg-theme-primary"
                >
                  <div className="w-full flex flex-col items-start gap-3 lg:gap-4 p-4 sm:p-6">
                    {renderDayTables()}
                    {totalTables < Object.keys(groupedSchedule).length && (
                      <button
                        className="self-center px-4 sm:px-5 py-2 sm:py-3 bg-theme-primary text-theme-white rounded-lg text-xs sm:text-sm hover:bg-opacity-10 hover:text-theme-primary transition-all"
                        onClick={() => setTotalTables(totalTables + 1)}
                      >
                        See More
                      </button>
                    )}
                  </div>
                </CustomScroll>
              </div>
              {/* <DataTableDemo tableData={schedule} /> */}
            </div>
          </div>
          <div className="w-full flex flex-col gap-5">
            <div className="theme-container w-full">
              <Select onValueChange={setSelectedDate}>
                <SelectTrigger className="w-[180px] bg-theme-white">
                  <SelectValue placeholder="Select Date" />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectLabel>Map Your Day</SelectLabel>
                    {uniqueDates.map((date) => (
                      <SelectItem
                        key={date}
                        value={date}
                        selected={selectedDate === date}
                      >
                        {moment(date).format("MMMM DD, YYYY")}
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
            <APIProvider apiKey={GOOGLE_MAPS_API_KEY} libraries={["marker"]}>
              <Map
                mapId={"bf51a910020fa25a"}
                style={{ width: "100%", height: "70vh" }}
                defaultCenter={defaultCenter}
                defaultZoom={13}
                mapStyle={MAP_STYLE_JSON}
                styles={MAP_STYLE_JSON}
              >
                {renderMarkersForSelectedDate()}
              </Map>
            </APIProvider>

            <div className="theme-container w-full flex items-center justify-center">
              <button
                className="bg-theme-primary text-theme-white rounded-md py-4 px-8 md:text-base text-sm md:px-10 lg:px-12 font-bold hover:scale-95 transition-all hover:bg-theme-black  border border-theme-primary hover:border-opacity-10 disabled:cursor-not-allowed disabled:bg-theme-primary disabled:scale-100 flex items-center gap-4 shadow-xl hover:shadow-none"
                onClick={handleCheckout}
              >
                I like it
                <HiArrowLongRight className="text-2xl" />
              </button>
            </div>
          </div>
        </>
      ) : (
        <NoData />
      )}
    </div>
  );
};

export default Itinerary;
