import React from 'react'
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu"
import { useRef } from 'react';
import { useState } from 'react';
import { ChevronDown } from 'lucide-react';

const FilterDropdown = ({ label, options, onChange, value }) => {
  const [isActive, setIsActive] = useState(false)
  const dropdownRef = useRef(null);
  const toggleColumnSelect = () => {
    setIsActive(!isActive);
  };
  // Function to handle clicks outside the dropdown menu
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsActive(false);
    }
  };

  // Function to handle checkbox item change
  const handleCheckboxChange = (option) => {
    const updatedValue = { ...value, [option.label]: !value[option.label] };

    // Call the onChange function passed as a prop
    onChange(updatedValue);
  };

  // Effect to add event listener when component mounts
  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <DropdownMenu open={isActive}>
      <DropdownMenuTrigger asChild>
        <button type="button" onClick={toggleColumnSelect} className='flex items-center justify-between text-sm outline-none w-full sm:w-auto'>
          {label} <ChevronDown className="ml-2 h-4 w-4" />
        </button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" ref={dropdownRef}>
        {options
          .map((column) => {
            return (
              <DropdownMenuCheckboxItem
                key={column.value}
                className="capitalize"
                checked={value[column.label]}
                onCheckedChange={() => handleCheckboxChange(column)}
              >
                {column.label}
              </DropdownMenuCheckboxItem>
            )
          })}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export default FilterDropdown