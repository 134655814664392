import React from 'react'
const HeroBanner = () => {
  return (
    <div className='theme-container w-full flex items-center justify-center'>
      <div className='w-full bg-hero-banner rounded-xl bg-no-repeat bg-center bg-cover overflow-hidden'>
        <div className='min-h-[200px] md:min-h-[210px] w-full py-6 px-10 flex flex-col items-center justify-center text-center bg-theme-primary/50 backdrop-blur-sm gap-2'>
          <h1 className='text-theme-white text-2xl md:text-3xl lg:text-4xl font-bold'>Experience the World, Your way</h1>
        </div>
      </div>
    </div>
  )
}

export default HeroBanner