import React, { createContext, useContext, useState } from 'react';

const ErrorPageContext = createContext();

export const useErrorPageContext = () => useContext(ErrorPageContext);

export const ErrorPageProvider = ({ children }) => {
  const [isErrorPage, setIsErrorPage] = useState(false);

  return (
    <ErrorPageContext.Provider value={{
      isErrorPage, setIsErrorPage
    }}>
      {children}
    </ErrorPageContext.Provider>
  );
};
