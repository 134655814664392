export const ROUTES = {
  home: "/",
  itinerary: "/itinerary",
  checkout: "/checkout",
  trips: "/trips",
  account: "/account",
}

export const SOCIAL_LINKS = {
  facebook: "#",
  instagram: "#",
  twitter: "#",
  linkedin: "#",
}