import React, { useEffect, useState } from 'react'

const CustomCheckbox = ({ name, register, validation, onChange, option, isLight, getValues }) => {
  const setRegister = register && register(name, validation);
  const [isActive, setIsActive] = useState(false)
  const value = getValues(name)
  useEffect(() => {
    setIsActive(value)
  }, [value])
  return (
    <>
      <input
        {...setRegister}
        id={option.value}
        type='checkbox'
        name={name}
        checked={value}
        className='hidden'
        onChange={(e) => {
          setRegister.onChange(e);
          onChange && onChange(e);
          setIsActive(e.target.checked)
        }}
      />
      <label htmlFor={option.value} className={`${isLight ? "bg-theme-white border-theme-primary/10" : "bg-theme-primary-opacity border-theme-primary-opacity" } py-2 px-2.5 text-xs text-theme-primary border hover:border-theme-primary/20 transition-all cursor-pointer rounded-full sm:w-full md:w-auto xl:w-full whitespace-nowrap text-center ${isActive ? "!border-theme-primary" : "border-theme-primary"}`}>{option?.label}</label>
    </>
  )
}

export default CustomCheckbox