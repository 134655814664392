import * as React from "react"
import { DayPicker } from "react-day-picker"
import { FaAngleRight, FaAngleLeft } from "react-icons/fa6";
import { addDays } from "date-fns";

import { cn } from "../../lib/utils"
import { buttonVariants } from "./button"

// Helper function to check if a date is before today
const isBeforeToday = (date) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Set time to the start of the day
  return date < today;
}

// Helper function to check if a date is within the 7-day range
const isWithinRange = (date, initialDate) => {
  if (!initialDate) return true;
  const endDate = addDays(initialDate, 7);
  return date >= initialDate && date <= endDate;
}

// Combined function to disable dates that are either before today or outside the 7-day range
const isDisabledDate = (date, initialDate) => {
  return isBeforeToday(date) || !isWithinRange(date, initialDate);
}

function Calendar({
  className,
  classNames,
  calenderFooter,
  showOutsideDays = true,
  initialDate,
  ...props
}) {
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn("p-3 w-full !relative", className)}
      footer={calenderFooter}
      disabled={(date) => isDisabledDate(date, initialDate)}
      classNames={{
        months: "flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0",
        tfoot: "",
        month: "space-y-4 w-full",
        caption: "flex justify-center pt-1 relative items-center",
        caption_label: "text-xs sm:text-sm font-medium",
        nav: "space-x-1 flex items-center",
        nav_button: cn(
          "h-7 w-7 flex items-center justify-center md:w-8 md:h-8 bg-theme-primary p-0 text-theme-white"
        ),
        nav_button_previous: "absolute left-1 border-theme-primary/50 text-theme-primary rounded-xl",
        nav_button_next: "absolute right-1 border-theme-primary/50 text-theme-primary rounded-xl",
        table: "w-full border-collapse space-y-1",
        head_row: "flex",
        head_cell:
          "text-muted-foreground rounded-md w-7 md:w-8 font-normal text-xs sm:text-xs",
        row: "flex w-full mt-2 text-xs sm:text-xs",
        cell: "h-7 w-7 md:h-8 md:w-8 text-center text-xs sm:text-xs p-0 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-accent/50 [&:has([aria-selected])]:bg-theme-primary/5 first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20",
        day: cn(
          buttonVariants({ variant: "ghost" }),
          "h-7 w-7 md:h-8 md:w-8 p-0 font-normal aria-selected:opacity-100 text-xs sm:text-xs "
        ),
        day_range_end: "day-range-end",
        day_selected:
          "bg-theme-primary text-primary-foreground hover:bg-primary hover:text-primary-foreground focus:bg-theme-primary focus:text-primary-foreground",
        day_today: "bg-accent text-accent-foreground",
        day_outside:
          "day-outside text-muted-foreground opacity-50 aria-selected:bg-accent/50 aria-selected:text-muted-foreground aria-selected:opacity-30",
        day_disabled: "text-muted-foreground opacity-50",
        day_range_middle:
          "aria-selected:bg-transparent aria-selected:text-theme-primary",
        day_hidden: "invisible",
        ...classNames,
      }}
      components={{
        IconLeft: ({ ...props }) => <FaAngleLeft className="text-xs sm:text-sm text-theme-white" />,
        IconRight: ({ ...props }) => <FaAngleRight className="text-xs sm:text-sm text-theme-white" />,
      }}
      {...props} >
      </DayPicker>
  );
}
Calendar.displayName = "Calendar"

export { Calendar }
