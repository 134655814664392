export const RefreshIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.33325 6.66667C1.33325 6.66667 1.41413 6.10051 3.75728 3.75736C6.10042 1.41421 9.89941 1.41421 12.2426 3.75736C13.0727 4.58754 13.6088 5.60048 13.8507 6.66667M1.33325 6.66667V2.66667M1.33325 6.66667H5.33325M14.6666 9.33333C14.6666 9.33333 14.5857 9.8995 12.2426 12.2426C9.89941 14.5858 6.10042 14.5858 3.75728 12.2426C2.9271 11.4125 2.39105 10.3995 2.14914 9.33333M14.6666 9.33333V13.3333M14.6666 9.33333H10.6666" stroke="#292929" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
)
export const LikeIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_167_4227)">
      <path d="M14.8821 6.98294C14.4453 6.45653 13.7615 6.12953 13.0973 6.12953H11.3405C11.7359 5.49947 12.0334 4.86544 12.2148 4.26169C12.4915 3.34038 12.4818 2.53725 12.1867 1.93913C11.8544 1.26544 11.1809 0.894409 10.2904 0.894409C10.1914 0.894412 10.0946 0.923822 10.0123 0.978912C9.92998 1.034 9.8659 1.11229 9.82816 1.20385C9.13478 2.88563 7.32622 5.30281 5.62225 6.89516C5.44837 6.29119 4.89116 5.84794 4.232 5.84794H2.09137C1.29359 5.84794 0.644531 6.497 0.644531 7.29475V13.6587C0.644531 14.4565 1.29359 15.1055 2.09137 15.1055H4.232C4.72003 15.1055 5.15209 14.8624 5.41428 14.4911C5.81091 14.8727 6.33672 15.1055 6.91287 15.1055H12.3029C12.8988 15.1055 13.4294 14.8518 13.8375 14.3718C14.1738 13.9761 14.4127 13.4415 14.5283 12.8258L15.3228 8.59375C15.4294 8.02594 15.2729 7.45388 14.8821 6.98294ZM4.67881 13.6587C4.67881 13.9051 4.47837 14.1056 4.232 14.1056H2.09137C1.845 14.1056 1.64453 13.9051 1.64453 13.6587V7.29478C1.64453 7.04841 1.845 6.84797 2.09137 6.84797H4.232C4.47837 6.84797 4.67881 7.04841 4.67881 7.29478V13.6587ZM14.3399 8.40925L13.5455 12.6414C13.4126 13.3492 13.0164 14.1056 12.3029 14.1056H6.91284C6.23241 14.1056 5.67884 13.4901 5.67884 12.7336V8.17922C7.56356 6.6146 9.67428 3.92744 10.6061 1.919C11.1032 2.00313 11.2398 2.27991 11.29 2.38153C11.6145 3.03938 11.3452 4.5541 9.98694 6.32525C9.93015 6.3993 9.89521 6.48775 9.88608 6.58062C9.87695 6.67349 9.89399 6.76706 9.93528 6.85074C9.97656 6.93442 10.0404 7.00488 10.1197 7.05415C10.1989 7.10341 10.2904 7.12952 10.3837 7.1295H13.0974C13.4661 7.1295 13.8646 7.3226 14.1126 7.62144C14.2522 7.78975 14.405 8.063 14.3399 8.40925Z" fill="#292929" />
    </g>
    <defs>
      <clipPath id="clip0_167_4227">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
export const DisLikeIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_167_4230)">
      <path d="M1.11788 9.01706C1.55466 9.54347 2.23853 9.87047 2.90266 9.87047H4.6595C4.26413 10.5005 3.96656 11.1346 3.78522 11.7383C3.5085 12.6596 3.51819 13.4627 3.81325 14.0609C4.1456 14.7346 4.81909 15.1056 5.70959 15.1056C5.80863 15.1056 5.90543 15.0762 5.98772 15.0211C6.07002 14.966 6.1341 14.8877 6.17184 14.7962C6.86522 13.1144 8.67378 10.6972 10.3778 9.10484C10.5516 9.70881 11.1088 10.1521 11.768 10.1521H13.9086C14.7064 10.1521 15.3555 9.503 15.3555 8.70525V2.34131C15.3555 1.54353 14.7064 0.894466 13.9086 0.894466H11.768C11.28 0.894466 10.8479 1.13762 10.5857 1.50887C10.1891 1.12728 9.66328 0.894466 9.08713 0.894466H3.69709C3.10122 0.894466 2.5706 1.14818 2.16253 1.62819C1.82616 2.0239 1.58725 2.5585 1.47169 3.17415L0.677221 7.40625C0.570627 7.97406 0.727127 8.54612 1.11788 9.01706ZM11.3212 2.34128C11.3212 2.0949 11.5216 1.89444 11.768 1.89444H13.9086C14.155 1.89444 14.3555 2.0949 14.3555 2.34128V8.70522C14.3555 8.95159 14.155 9.15203 13.9086 9.15203H11.768C11.5216 9.15203 11.3212 8.95159 11.3212 8.70522V2.34128ZM1.66006 7.59075L2.4545 3.35862C2.58738 2.65078 2.9836 1.89444 3.69713 1.89444H9.08716C9.76759 1.89444 10.3212 2.50987 10.3212 3.26637V7.82078C8.43644 9.3854 6.32572 12.0726 5.39391 14.081C4.89675 13.9969 4.76019 13.7201 4.71003 13.6185C4.3855 12.9606 4.65485 11.4459 6.01306 9.67475C6.06985 9.6007 6.10479 9.51225 6.11392 9.41938C6.12305 9.32651 6.10601 9.23294 6.06472 9.14926C6.02344 9.06558 5.95955 8.99512 5.8803 8.94585C5.80105 8.89659 5.7096 8.87048 5.61628 8.8705H2.90263C2.53388 8.8705 2.13538 8.6774 1.88744 8.37856C1.74785 8.21025 1.59503 7.937 1.66006 7.59075Z" fill="#292929" />
    </g>
    <defs>
      <clipPath id="clip0_167_4230">
        <rect width="16" height="16" fill="white" transform="matrix(-1 0 0 -1 16 16)" />
      </clipPath>
    </defs>
  </svg>
)

export const FilterIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.14617 14.7808H0.625C0.279844 14.7808 0 15.0606 0 15.4058C0 15.751 0.279844 16.0308 0.625 16.0308H4.14617C4.42688 17.1649 5.45277 18.0084 6.67242 18.0084C7.89211 18.0084 8.91805 17.1649 9.19875 16.0308H19.375C19.7202 16.0308 20 15.751 20 15.4058C20 15.0606 19.7202 14.7808 19.375 14.7808L9.19871 14.7808C8.91801 13.6466 7.89207 12.8031 6.67238 12.8031C5.45273 12.8031 4.42684 13.6467 4.14617 14.7808ZM8.02508 15.4058C8.02508 16.1516 7.41828 16.7584 6.67238 16.7584C5.92656 16.7584 5.31977 16.1516 5.31977 15.4058C5.31977 14.6599 5.92656 14.0531 6.67238 14.0531C7.41828 14.0531 8.02508 14.6599 8.02508 15.4058ZM10.8013 9.37502H0.625C0.279844 9.37502 0 9.65487 0 10C0 10.3452 0.279844 10.625 0.625 10.625H10.8013C11.082 11.7592 12.1079 12.6027 13.3276 12.6027C14.5473 12.6027 15.5732 11.7592 15.8539 10.625H19.375C19.7202 10.625 20 10.3452 20 10C20 9.65487 19.7202 9.37502 19.375 9.37502H15.8538C15.5731 8.24088 14.5472 7.39741 13.3275 7.39741C12.1079 7.39741 11.082 8.24088 10.8013 9.37502ZM14.6802 10C14.6802 10.7459 14.0734 11.3527 13.3275 11.3527C12.5817 11.3527 11.9749 10.7459 11.9749 10C11.9749 9.2542 12.5817 8.64741 13.3275 8.64741C14.0734 8.64741 14.6802 9.25416 14.6802 10ZM6.67238 1.99162C5.45273 1.99162 4.42684 2.8351 4.14613 3.96924H0.625C0.279844 3.96924 0 4.24909 0 4.59424C0 4.9394 0.279844 5.21924 0.625 5.21924H4.14617C4.42688 6.35342 5.45277 7.1969 6.67242 7.1969C7.89211 7.1969 8.91805 6.35342 9.19875 5.21924L19.375 5.21924C19.7202 5.21924 20 4.9394 20 4.59424C20 4.24909 19.7202 3.96924 19.375 3.96924L9.19871 3.96924C8.91805 2.8351 7.89207 1.99162 6.67238 1.99162ZM8.02508 4.59424C8.02508 5.3401 7.41828 5.9469 6.67238 5.9469C5.92656 5.9469 5.31977 5.3401 5.31977 4.59424C5.31977 3.84842 5.92656 3.24162 6.67238 3.24162C7.41828 3.24162 8.02508 3.84842 8.02508 4.59424Z" fill="#292929" />
  </svg>

)