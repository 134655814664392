import React from 'react'
import CustomCheckbox from './CustomCheckbox';

const CheckboxGroup = ({ name, label, register, validation, onChange, options, isLight, getValues }) => {
  return (
    <div className='flex w-full flex-col items-start gap-4'>
      <p className='text-sm sm:text-base font-bold'>{label}</p>
      <div className='w-full grid grid-cols-2 sm:flex items-center flex-wrap sm:flex-nowrap md:flex-wrap xl:flex-nowrap gap-1.5'>
        {
          options?.map((option) => {
            return (
              <CustomCheckbox
                option={option} 
                register={register}
                validation={validation}
                onChange={onChange}
                name={`${name}.${option.value}`}
                isLight={isLight}
                getValues={getValues}
              />
            )
          })
        }
      </div>
    </div>
  )
}

export default CheckboxGroup