import { useScheduleContext } from "provider/ScheduleContext";
// import HeroBanner from 'components/custom/HeroBanner'
import React, { useMemo } from "react";
import { MdShoppingCartCheckout } from "react-icons/md";
import CheckoutImg from "assets/images/checkout-banner.png";
import moment from "moment";
import NoData from "components/custom/NoData";
import NoDataVector from "assets/images/no-data.svg";
import { Accordion, AccordionItem } from "@nextui-org/react";

const Checkout = () => {
  const { checkoutItems, schedulePayload } = useScheduleContext();
  // const [selectedDate, setSelectedDate] = useState(null);

  console.log("checkoutItems from Checkout", checkoutItems);

  const filteredItems = useMemo(() => {
    return checkoutItems.filter((item) => item.viatorInput);
  }, [checkoutItems]);

  // const uniqueDates = useMemo(() => {
  //   const dates = Array.from(new Set(filteredItems.map((item) => item.date)));
  //   return dates;
  // }, [filteredItems]);

  // const itemsBySelectedDate = useMemo(() => {
  //   if (!selectedDate) return [];
  //   return filteredItems.filter(item => item.date === selectedDate);
  // }, [filteredItems, selectedDate]);

  console.log("filteredItems", filteredItems);

  const totalDuration = useMemo(() => {
    return filteredItems.reduce((total, item) => total + item.duration, 0);
  }, [filteredItems]);

  const totalBudget = useMemo(() => {
    return filteredItems.reduce((total, item) => total + item.budget, 0);
  }, [filteredItems]);

  const startDate = useMemo(() => {
    const dates = filteredItems.map((item) => moment(item.date));
    return dates.length ? moment.min(dates).format("ddd. DD MMM, YYYY") : "";
  }, [filteredItems]);

  const endDate = useMemo(() => {
    const dates = filteredItems.map((item) => moment(item.date));
    return dates.length ? moment.max(dates).format("ddd. DD MMM, YYYY") : "";
  }, [filteredItems]);

  const handleRedirect = (e) => {
    console.log(e);
  };

  return (
    <div
      className={`w-full min-h-[calc(100vh-72px)] bg-theme-white bg-repeat-y bg-left-top bg-cover flex flex-col items-center gap-7 pb-7 ${
        !checkoutItems || checkoutItems?.length === 0 ? "justify-center" : ""
      }`}
    >
      {checkoutItems && checkoutItems?.length > 0 ? (
        <>
          <div className="theme-container w-full flex flex-col items-start gap-1">
            <div className="w-full bg-theme-primary-opacity rounded-lg md:rounded-xl overflow-hidden">
              <img
                src={CheckoutImg}
                alt="travel"
                className="w-full bg-theme-primary-opacity h-28 object-cover lg:h-auto"
              />
            </div>
          </div>
          {filteredItems?.length > 0 ? (
            <div className="theme-container w-full flex flex-col items-start gap-1">
              <h4 className="md:text-lg lg:text-xl xl:text-2xl font-bold text-theme-primary px-3">
                Summary of Your Tours
              </h4>
              <p className="md:text-base sm:text-sm text-xs px-3">
                Ranked based
              </p>

              <div className="py-7 w-full border-t border-theme-black/20 mt-5">
                <Accordion variant="light" className="w-full">
                  <AccordionItem
                    key="1"
                    aria-label="checkoutAccordian"
                    title={
                      <div className="w-full flex items-center gap-5 flex-wrap justify-between text-base md:text-lg lg:text-xl font-bold">
                        <div className="flex items-center gap-x-4 flex-wrap">
                          <h5>
                            {startDate} - {endDate}
                          </h5>
                          <h5>{(totalDuration / 60).toFixed(2)} Hours</h5>
                        </div>
                        <h5 className="font-extrabold text-lg md:text-xl lg:text-2xl">
                          {schedulePayload?.budget?.currency?.code || "€"}{" "}
                          {totalBudget?.toFixed(2)}
                        </h5>
                      </div>
                    }
                    className="customAccordian "
                    classNames={{
                      base: "bg-theme-primary-opacity rounded rounded-xl md:rounded-2xl border border-theme-primary/5 ",
                      heading: "px-7 py-1 font-bold",
                      title:
                        "text-theme-primary text-base md:text-lg lg:text-xl ",
                      trigger: "py-3 lg:py-4",
                      indicator: "text-xl text-theme-primary",
                    }}
                  >
                    <div className="w-full bg-theme-primary-opacity rounded-xl px-7 pb-4 flex flex-col items-start gap-5 text-base md:text-lg lg:text-xl font-bold">
                      <div className="w-full flex flex-col items-start gap-5">
                        {filteredItems && filteredItems?.length > 0 ? (
                          <div className="flex flex-col items-start gap-3">
                            {filteredItems?.map((data, i) => {
                              return (
                                <div
                                  key={i}
                                  className="flex items-center flex-wrap gap-x-3 text-sm font-normal"
                                >
                                  <p className="font-bold text-theme-primary">
                                    Day {i + 1}
                                  </p>
                                  <p>-</p>
                                  <div
                                    key={i}
                                    className="flex items-center flex-wrap gap-x-3"
                                  >
                                    <p>{data?.activity_name} </p>
                                    <p className="font-bold text-theme-black">
                                      € {data?.budget}
                                    </p>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </AccordionItem>
                </Accordion>
              </div>
              <button
                className="bg-theme-primary text-theme-white rounded-md py-4 px-6 sm:px-8 md:text-base text-xs sm:text-sm md:px-10 lg:px-12 font-bold hover:scale-95 transition-all hover:bg-theme-black  border border-theme-primary hover:border-opacity-10 disabled:cursor-not-allowed disabled:bg-theme-primary disabled:scale-100 flex items-center gap-2 md:gap-4 shadow-xl hover:shadow-none self-center lg:self-end"
                onClick={handleRedirect}
              >
                Continue to Checkout
                <MdShoppingCartCheckout className="text-base sm:text-xl md:text-2xl" />
              </button>
            </div>
          ) : (
            <div className="theme-container w-full flex flex-col items-start gap-1">
              <div className="w-full bg-theme-primary-opacity rounded-lg md:rounded-xl overflow-hidden text-center flex flex-col items-center justify-center px-5 py-7">
                <img
                  src={NoDataVector}
                  alt="no data found"
                  className="w-32 sm:w-40 md:w-48 lg:w-52"
                />
                <h3 className="text-sm sm:text-base md:text-lg lg:text-xl font-bold text-theme-primary uppercase">
                  No Items to checkout from the list you select
                </h3>
              </div>
            </div>
          )}
        </>
      ) : (
        <NoData />
      )}
    </div>
  );
};

export default Checkout;
