import React from 'react'
import { DatePickerWithRange } from './DatePickerWithRange'
import { Controller } from 'react-hook-form'

const CustomDateRange = ({
  label,
  required,
  helpTxt,
  name,
  control,
  error,
  validation,
  reset,
  inputClass
}) => {
  return (
    <div className="flex w-full flex-col items-start gap-2">
      {label && (
        <label className="text-sm sm:text-base font-bold">
          {label} 
        </label>
      )}
      <Controller
        name={name}
        control={control}
        rules={validation}
        render={({ field: { onChange, value = [], ref } }) => (
          <DatePickerWithRange inputClass={inputClass} value={value} onChange={onChange} reset={reset} />
        )}
      />
      {helpTxt && (
        <span className="text-theme-secondary text-xs md:text-xs">
          {helpTxt}
        </span>
      )}
      {error && error?.message && (
        <span className="text-red-500 text-xs md:text-xs">
          {error?.message}
        </span>
      )}
    </div>
  )
}

export default CustomDateRange