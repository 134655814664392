import React, { useState } from "react";
import { Controller } from "react-hook-form";
import Autocomplete from "react-google-autocomplete";
import { VALIDATION } from "constants/validation";
import { GOOGLE_MAPS_API_KEY } from "constants";
import { CURRENCIES } from "constants/currencies";
import countries from "country-data";
import CustomDateRange from "./CustomDateRange";
import CustomSelectController from "./CustomSelectController";
import CustomInput from "./CustomInput";
import CheckboxGroup from "./CheckboxGroup";
import { DIETRY, TRANSPORTATION, VACATION_TYPE } from "constants";
import TimeRangeSlider from "./TimeRangeSlider";
import { FilterIcon } from "constants/icons";
import FilterDropdown from "./FilterDropdown";
import { useEffect } from "react";
import { DESTIATIONS } from "constants";

const CommonForm = ({
  control,
  errors,
  setValue,
  reset,
  register,
  isShowFilters,
  loading,
  isButtonHide,
  baseClass,
  isOtherPage,
  getValues,
}) => {
  const [currencyOptions, setCurrencyOptions] = useState(CURRENCIES);
  const [allFilterActive, setAllFilterActive] = useState(false);
  const [selectedTransportation, setSelectedTransportation] = useState({});
  const [selectedVacationType, setSelectedVacationType] = useState({});
  const [currencySuggestion, setCurrencySuggestion] = useState(null);
  const [selectedDietaryRestrictions, setSelectedDietaryRestrictions] =
    useState({});
  const values = getValues();

  const getCurrency = (place, checkBudgetValue) => {
    const countryCode = place?.address_components?.find((component) =>
      component?.types?.includes("country")
    )?.short_name;
    const country = countries?.countries?.[countryCode];
    const currency = country ? country?.currencies?.[0] : "Unknown";

    const selectedIndex = currencyOptions.findIndex(
      (option) => option.code === currency
    );
    if (selectedIndex !== -1) {
      // Move the selected currency option to the top of the list
      const updatedOptions = [
        currencyOptions[selectedIndex],
        ...currencyOptions.slice(0, selectedIndex),
        ...currencyOptions.slice(selectedIndex + 1),
      ];
      if (checkBudgetValue) {
        setValue("budget.currency", null);
      }
      setCurrencyOptions(updatedOptions);
      setCurrencySuggestion(updatedOptions?.[0]);
    }
  };

  useEffect(() => {
    if (isOtherPage) {
      console.log("values", values);
      const transportationOptions = values?.transportationOptions;
      const vacationTypeOptions = values?.vacationType;
      const dietaryRestrictionsOptions = values?.dietaryRestrictions;

      const selectedTransportationOptions = Object.keys(
        transportationOptions
      ).reduce((acc, option) => {
        acc[option] = transportationOptions[option];
        return acc;
      }, {});

      const selectedVacationTypeOptions = Object.keys(
        vacationTypeOptions
      ).reduce((acc, option) => {
        acc[option] = vacationTypeOptions[option];
        return acc;
      }, {});

      const selectedDietaryRestrictionsOptions = Object.keys(
        dietaryRestrictionsOptions
      ).reduce((acc, option) => {
        acc[option] = dietaryRestrictionsOptions[option];
        return acc;
      }, {});

      setSelectedTransportation(selectedTransportationOptions);
      setSelectedVacationType(selectedVacationTypeOptions);
      setSelectedDietaryRestrictions(selectedDietaryRestrictionsOptions);
    }
  }, [
    values?.transportationOptions,
    values?.vacationType,
    values?.dietaryRestrictions,
  ]);
  return (
    <>
      <div
        className={`bg-theme-primary-opacity w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-14 gap-y-5 p-7 rounded-xl md:rounded-2xl z-20 ${baseClass}`}
      >
        {/* <div className={`w-full h-auto flex flex-col items-start gap-2`}>
          <p className={`text-sm sm:text-base font-bold`}>
            Destination
          </p>
          <Controller
            name="destination"
            control={control}
            rules={{ ...VALIDATION.required }}
            render={({ field: { onChange, value, ref } }) => (
              <Autocomplete
                apiKey={YOUR_GOOGLE_MAPS_API_KEY}
                ref={ref}
                placeholder= "Enter a city"
                value={value}
                className={`bg-transparent border border-theme-black outline-0 w-full !px-3 !py-3 md:px-4 md:py-3 rounded-lg focus:ring-0 focus-visible:ring-0 focus-visible:outline-none text-xs sm:text-xs disabled:cursor-not-allowed disabled:border-theme-dark-gray disabled:border-opacity-50 disabled:text-theme-dark-gray disabled:text-opacity-50 placeholder-theme-black ${errors?.destination ? "border !border-red-400" : ""}`}
                onPlaceSelected={(place) => {
                  console.log(place)
                  setValue("destination", place?.formatted_address);
                  getCurrency(place);
                }}
                onChange={onChange}
              />
            )}
          />
          {errors?.destination && (
            <span className="text-red-500 text-xs md:text-xs">
              {errors?.destination?.message}
            </span>
          )}
        </div> */}
        <CustomSelectController
          label="Destination"
          options={DESTIATIONS}
          closeMenuOnSelect
          name="destination"
          control={control}
          baseClass=""
          handleChangeFunc={(place) => {
            getCurrency(place, true);
          }}
          placeholder="Enter a City"
          // isCenter
          getOptionLabel={(option) => `${option.formatted_address}`}
          getOptionValue={(option) => option.formatted_address}
          error={errors?.destination}
          inputClass={`${errors?.destination && "!border-red-400"}`}
          validation={VALIDATION.required}
          hideArrow
          // isLazy
          required
        />
        <CustomDateRange
          label="Dates"
          name="dates"
          control={control}
          error={errors.dates}
          inputClass={errors.dates && "border-red-400"}
          validation={VALIDATION.required}
          reset={() => {
            setValue("dates", null);
          }}
          required
        />
        <div className="w-full flex flex-col items-start gap-2">
          <div className="w-full flex items-start gap-0">
            <CustomSelectController
              label="Budget"
              options={currencyOptions}
              closeMenuOnSelect
              name="budget.currency"
              control={control}
              baseClass="!w-[40%] sm:!w-[25%] md:w-[50%] lg:!w-[40%]"
              isCenter
              placeholder={currencySuggestion?.code || "Select"}
              isPlaceholderLight={currencySuggestion?.code}
              // error={errors?.budget?.currency}
              getOptionLabel={(option) => `${option.code}`}
              getOptionValue={(option) => option.code}
              inputClass={`${
                errors?.budget?.currency && "!border-red-400"
              } border-r-0 rounded-r-none`}
              validation={VALIDATION.required}
              required
            />
            <CustomInput
              label="amount"
              hideLable
              type="number"
              name="budget.amount"
              placeholder="Enter amount"
              register={register}
              inputClass={`${
                errors?.budget?.amount && "!border-red-500"
              } border-l-0 rounded-l-none`}
              validation={{ ...VALIDATION.number, ...VALIDATION.required }}
              // error={errors?.budget?.amount}
            />
          </div>
          {(errors?.budget?.currency || errors?.budget?.amount) && (
            <span className="text-red-500 text-xs md:text-xs">
              {errors?.budget?.currency?.message ||
                errors?.budget?.amount?.message}
            </span>
          )}
        </div>
      </div>
      {isShowFilters && (
        <div
          className={`w-full flex flex-col gap-5 ${
            isOtherPage ? "px-7 mb-4" : "my-7"
          }`}
        >
          {isOtherPage && (
            <div className="flex items-start md:items-center flex-col md:flex-row flex-wrap gap-6">
              <button
                type="button"
                className="flex items-center gap-2"
                onClick={() => setAllFilterActive(!allFilterActive)}
              >
                <FilterIcon />
                <p className="text-sm font-bold">All Filters</p>
              </button>
              <div className="md:self-start self-end flex items-start sm:items-center sm:flex-row flex-col flex-wrap gap-6 w-full sm:w-auto">
                <Controller
                  name="transportationOptions"
                  control={control}
                  rules={{ ...VALIDATION.required }}
                  render={({ field: { onChange, value, ref } }) => (
                    <FilterDropdown
                      label="Transportation"
                      options={TRANSPORTATION}
                      value={selectedTransportation}
                      onChange={(selectedOptions) => {
                        setSelectedTransportation(selectedOptions);
                        onChange(selectedOptions); // Pass selected options to react-hook-form
                      }}
                    />
                  )}
                />
                <Controller
                  name="vacationType"
                  control={control}
                  rules={{ ...VALIDATION.required }}
                  render={({ field: { onChange, value, ref } }) => (
                    <FilterDropdown
                      label="Vacation Type"
                      options={VACATION_TYPE}
                      value={selectedVacationType}
                      onChange={(selectedOptions) => {
                        setSelectedVacationType(selectedOptions);
                        onChange(selectedOptions); // Pass selected options to react-hook-form
                      }}
                    />
                  )}
                />
                <Controller
                  name="dietaryRestrictions"
                  control={control}
                  rules={{ ...VALIDATION.required }}
                  render={({ field: { onChange, value, ref } }) => (
                    <FilterDropdown
                      label="Dietary Restrictions"
                      options={DIETRY}
                      value={selectedDietaryRestrictions}
                      onChange={(selectedOptions) => {
                        setSelectedDietaryRestrictions(selectedOptions);
                        onChange(selectedOptions); // Pass selected options to react-hook-form
                      }}
                    />
                  )}
                />
              </div>
            </div>
          )}
          {!isOtherPage && (
            <h5 className="text-xl text-theme-black font-bold">Filters</h5>
          )}
          {((isOtherPage && allFilterActive) ||
            (!isOtherPage && isShowFilters)) && (
            <div className=" w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 rounded-xl md:rounded-2xl">
              {!isOtherPage && (
                <>
                  <CheckboxGroup
                    name="transportationOptions"
                    label="Transportation"
                    register={register}
                    options={TRANSPORTATION}
                    isLight={isOtherPage}
                    getValues={getValues}
                  />
                  <CheckboxGroup
                    name="vacationType"
                    label="Vacation Type"
                    register={register}
                    options={VACATION_TYPE}
                    isLight={isOtherPage}
                    getValues={getValues}
                  />
                  <CheckboxGroup
                    name="dietaryRestrictions"
                    label="Dietary Restrictions"
                    register={register}
                    options={DIETRY}
                    isLight={isOtherPage}
                    getValues={getValues}
                  />
                </>
              )}
              <div className={`w-full h-auto flex flex-col items-start gap-2`}>
                <p className={`text-sm sm:text-base font-bold`}>Stay Details</p>
                <Controller
                  name="stayDetails"
                  control={control}
                  // rules={{ ...VALIDATION.required }}
                  render={({ field: { onChange, value, ref } }) => (
                    <Autocomplete
                      apiKey={GOOGLE_MAPS_API_KEY}
                      ref={ref}
                      className={`bg-transparent border border-theme-black outline-0 w-full !px-3 !py-3 md:px-4 md:py-3 rounded-lg focus:ring-0 focus-visible:ring-0 focus-visible:outline-none text-xs sm:text-xs disabled:cursor-not-allowed disabled:border-theme-dark-gray disabled:border-opacity-50 disabled:text-theme-dark-gray disabled:text-opacity-50 placeholder-theme-black ${
                        errors?.stayDetails ? "border !border-red-400" : ""
                      }`}
                      onPlaceSelected={(place) => {
                        console.log("place", place);
                        setValue("stayDetails", place?.formatted_address);
                        getCurrency(place);
                      }}
                      value={value}
                      options={{
                        types: ["geocode", "establishment"],
                      }}
                      onChange={onChange}
                    />
                  )}
                />
                {errors?.stayDetails && (
                  <span className="text-red-500 text-xs md:text-xs">
                    {errors?.stayDetails?.message}
                  </span>
                )}
              </div>
              <div className={`w-full flex flex-col items-start gap-2.5`}>
                <Controller
                  name="timeOfArrival"
                  control={control}
                  rules={{ ...VALIDATION.required }}
                  render={({ field: { onChange, value = 0, ref } }) => (
                    <TimeRangeSlider
                      onChange={onChange}
                      value={value}
                      setValue={setValue}
                      name="timeOfArrival"
                      label="Time of Arrival"
                    />
                  )}
                />
                <span className="text-red-500 text-xs">
                  {errors?.timeOfArrival?.message}
                </span>
              </div>
              <div className={`w-full  flex flex-col items-start gap-2.5`}>
                <Controller
                  name="timeOfDeparture"
                  control={control}
                  rules={{ ...VALIDATION.required }}
                  render={({ field: { onChange, value = 0, ref } }) => (
                    <TimeRangeSlider
                      onChange={onChange}
                      value={value}
                      setValue={setValue}
                      name="timeOfDeparture"
                      label="Time of Departure"
                    />
                  )}
                />
                <span className="text-red-500 text-xs">
                  {errors?.timeOfDeparture?.message}
                </span>
              </div>
            </div>
          )}
        </div>
      )}
      {!isButtonHide && (
        <button
          disabled={loading}
          type="submit"
          className={`bg-theme-primary text-theme-white rounded-md py-4 px-8 md:text-base text-sm md:px-10 lg:px-20 font-bold hover:scale-95 transition-all hover:bg-theme-black  border border-theme-primary hover:border-opacity-10 disabled:cursor-not-allowed disabled:bg-theme-primary disabled:scale-100 flex items-center gap-4 ${
            isShowFilters ? "mt-4 md:mt-10" : "mt-20 md:mt-32"
          }`}
        >
          {" "}
          {loading && (
            <svg
              aria-hidden="true"
              className="w-3 h-3 lg:w-5 lg:h-5 text-gray-200 animate-spin fill-theme-primary"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
          )}{" "}
          Plan for Me!
        </button>
      )}
    </>
  );
};

export default CommonForm;
