import * as React from "react"
import { addDays, format } from "date-fns"
import { Calendar as CalendarIcon } from "lucide-react"

import { cn } from "../../lib/utils"
import { Button } from "../ui/button"
import { Calendar } from "../ui/calendar"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../ui/popover"
import useWindowSize from "hooks/useWindow"
import { useState } from "react"
import { useRef } from "react"
import { useEffect } from "react"

export function DatePickerWithRange({
  className, value, inputClass, onChange, reset
}) {
  const [openPopup, setOpenPopup] = useState(false)
  const [initialDate, setInitialDate] = useState(value?.from || null);
  const popupRef = useRef(null);
  const calendarRef = useRef(null);
  const [isDateSelected, setIsDateSelected] = useState(false);


  const togglePopup = () => {
    setOpenPopup(!openPopup)
  }

  const closePopup = () => {
    setOpenPopup(false);
  };

  const handleClickOutside = (event) => {
    if (
      popupRef.current &&
      !popupRef.current.contains(event.target) &&
      calendarRef.current &&
      !calendarRef.current.contains(event.target)
    ) {
      closePopup();
    }
  };

  const handleReset = () => {
    setInitialDate(null);
    setIsDateSelected(false);
    reset();
  };

  useEffect(() => {
    if (openPopup) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openPopup]);

  const [width] = useWindowSize()

  const handleDateChange = (selectedDate) => {
    if (selectedDate?.from) {
      setInitialDate(selectedDate.from);
      setIsDateSelected(true);
    } else {
      setIsDateSelected(false);
    }
    onChange(selectedDate);
  };

  const calenderFooter = (
    <div className="w-full flex flex-col items-center gap-2">
      <div className='w-full flex items-center gap-2 pt-3 justify-between'>
        <button className='text-theme-primary w-full rounded-md bg-theme-primary/10 px-6 py-1.5 md:py-2 text-[10px] sm:text-xs' onClick={handleReset}>Reset</button>
        <button className='text-theme-white w-full rounded-md bg-theme-primary px-6 py-1.5 md:py-2 text-[10px] sm:text-xs' onClick={() => setOpenPopup(false)}>Done</button>
      </div>
      <p className="italic text-[9px] md:text-[10px] text-theme-primary">Maximum 7 Days per Destination</p>
    </div>
  )

  return (
    <div className={cn("w-full grid gap-2", className)}>
      <Popover open={openPopup}>
        <PopoverTrigger asChild>
          <div ref={popupRef}>
            <Button
              id="date"
              type="button"
              variant={"dateRange"}
              size="dateRange"
              onClick={togglePopup}
              className={cn(
                `border border-theme-black rounded-lg flex items-center justify-between text-xs sm:text-xs w-full bg-transparent font-normal ${inputClass}`,
                !value && "text-muted-foreground"
              )}
            >
              {value?.from ? (
                value.to ? (
                  <>
                    {format(value.from, "LLL dd, y")} -{" "}
                    {format(value.to, "LLL dd, y")}
                  </>
                ) : (
                  format(value.from, "LLL dd, y")
                )
              ) : (
                <span>Pick a date</span>
              )}
              <CalendarIcon className="mr-2 h-4 w-4" />
            </Button>
          </div>
        </PopoverTrigger>
        <PopoverContent className="w-full relative p-0" align="center">
          <div ref={calendarRef}>
            <Calendar
              initialFocus
              mode="range"
              calenderFooter={calenderFooter}
              defaultMonth={value?.from}
              selected={value}
              onSelect={handleDateChange}
              numberOfMonths={width < 640 ? 1 : 2}
              initialDate={initialDate}
            />
          </div>
        </PopoverContent>
      </Popover>
    </div>
  )
}
