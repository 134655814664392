import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import AnimatedPlaneGif from 'assets/images/airplane-3.gif'; // Update the path accordingly

const LoadingScreen = ({ textLines, currentTextIndex }) => (
  <div className="fixed top-0 left-0 w-full h-full flex flex-col justify-center items-center bg-theme-white z-50 gap-2">
    <img src={AnimatedPlaneGif} alt="loading" className='w-[110px]' />
    <p className='text-sm text-center sm:text-sm lg:text-base text-theme-primary motion-safe:animate-bounce'>
      {textLines[currentTextIndex]}
    </p>
  </div>
);

const LoadingScreenPortal = ({ isOpen }) => {
  const textLines = [
    'Finding the best tours for you',
    'Optimizing all your routes',
    'Finding the most delicious restaurants',
    'Putting it all together'
  ];

  const [currentTextIndex, setCurrentTextIndex] = useState(0);

  useEffect(() => {
    if (!isOpen) return;

    const intervalId = setInterval(() => {
      setCurrentTextIndex((prevIndex) => (prevIndex + 1) % textLines.length);
    }, 3000); // Change text every 3 seconds

    return () => clearInterval(intervalId);
  }, [isOpen, textLines.length]);
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <LoadingScreen textLines={textLines} currentTextIndex={currentTextIndex} />,
    document.getElementById('loading-screen-portal')
  );
};

export default LoadingScreenPortal;