import { DEMO_CHECKOUT_DATA } from 'constants';
import { DEMO_DATA, DEMO_PAYLOAD } from 'constants';
import React, { createContext, useContext, useState } from 'react';

const ScheduleContext = createContext();

export const useScheduleContext = () => useContext(ScheduleContext);

export const ScheduleProvider = ({ children }) => {
  const [schedule, setSchedule] = useState([]);
  const [checkoutItems, setCheckoutItems] = useState([]);
  const [schedulePayload, setSchedulePayload] = useState(null);
  const [isScheduleLoading, setIsScheduleLoading] = useState(false);

  const setScheduleData = (data) => {
    setSchedule(data);
  };

  return (
    <ScheduleContext.Provider value={{
      schedule, setScheduleData, schedulePayload, setSchedulePayload, isScheduleLoading, setIsScheduleLoading, checkoutItems, setCheckoutItems }}>
      {children}
    </ScheduleContext.Provider>
  );
};
